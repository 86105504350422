<template>
  <div>
    <otp-form />
  </div>
</template>
<script>
import OtpForm from "@/components/Auth/OtpForm";

export default {
  components: { OtpForm },
};
</script>
<style></style>
