<template>
  <div>
    <div class="splash-container min-vh-100">
      <div class="card card-border-color card-border-color-secondary">
        <div class="card-body">
          <div class="text-center">
            <img
                class="logo-img"
                src="/img/brand/logo.png"
                alt="Mshiriki"
                width="180"
                height="70"
            />
            <h4>OTP</h4>
            <p class="mb-4">Please enter the OTP sent to your email.</p>
          </div>
          <hr/>
          <div class="my-4">
            <div v-show="error" class="alert alert-danger">{{ error }}</div>
            <form @submit.prevent="submitOtp()">
              <div class="form-group">
                <label for="otp">OTP:</label>
                <input
                    v-model="otp"
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    class="form-control"
                    required
                    id="otp"
                />
                <small class="text-danger">{{ errors.first("otp") }}</small>
              </div>
              <div class="form-group">
                <button
                    :disabled="submitting"
                    class="btn btn-primary btn-block"
                    type="submit"
                >
                  {{ submitting ? "LOADING..." : "SUBMIT" }}
                </button>
              </div>
              <div class="form-group">
                <router-link :to="{ name: 'login' }">
                  Back to log in
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "OtpForm",
  data() {
    return {
      submitting: false,
      otp: "",
      error: "",
    };
  },
  methods: {
    ...mapActions(["verifyTwoFaToken"]),
    submitOtp() {
      this.submitting = true;
      this.verifyTwoFaToken({token: this.otp})
          .then(() => {
            this.submitting = false;
          });
    },
  }
};
</script>

